import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';
import auth from '@/router/auth';
import user from '@/router/user';
import NProgress from 'nprogress';
import NotFound from '@/views/Users/NotFound';

Vue.use(VueRouter);

const routes = [
    user,
    appRoutes,
    auth,
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (from.path !== to.path) {
            if (to.hash) {
                return { selector: to.hash };
            } else {
                return { x: 0, y: 0 };
            }
        }
    }
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;

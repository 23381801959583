<template>
    <div>
        <form method="POST" id="formid" :action="model.action" ref="form">
            <input type="hidden" id="address1" name="address1" :value="model.address1">
            <input type="hidden" id="address2" name="address2" :value="model.address2">
            <input type="hidden" id="amount" name="amount" :value="model.amount">
            <input type="hidden" id="city" name="city" :value="model.city">
            <input type="hidden" id="country" name="country" :value="model.country">
            <input type="hidden" id="email" name="email" :value="model.email">
            <input type="hidden" id="firstname" name="firstname" :value="model.firstname">
            <input type="hidden" id="furl" name="furl" :value="model.furl">
            <input type="hidden" id="hash_string" name="hash_string" :value="model.hash_string">
            <input type="hidden" id="hash" name="hash" :value="model.hash">
            <input type="hidden" id="key" name="key" :value="model.key">
            <input type="hidden" id="lastname" name="lastname" :value="model.lastname">
            <input type="hidden" id="merchant_key" name="merchant_key" :value="model.merchant_key">
            <input type="hidden" id="phone" name="phone" :value="model.phone">
            <input type="hidden" id="productinfo" name="productinfo" :value="model.productinfo">
            <input type="hidden" id="service_provider" name="service_provider" :value="model.service_provider">
            <input type="hidden" id="state" name="state" :value="model.state">
            <input type="hidden" id="surl" name="surl" :value="model.surl">
            <input type="hidden" id="txnid" name="txnid" :value="model.txnid">
            <input type="hidden" id="udf1" name="udf1" :value="model.udf1">
            <input type="hidden" id="udf2" name="udf2" :value="model.udf2">
            <input type="hidden" id="udf3" name="udf3" :value="model.udf3">
            <input type="hidden" id="udf4" name="udf4" :value="model.udf4">
            <input type="hidden" id="udf5" name="udf5" :value="model.udf5">
            <input type="hidden" id="zipcode" name="zipcode" :value="model.zipcode">
            <input type="hidden" id="Content-Type" name="Content-Type" value="application/json"/>
        </form>
    </div>
</template>

<script>
export default {
    name: 'RedirectPage',
    props: { model: { type: Object, required: true } },
    mounted () {
        this.$refs.form.submit();
    }
};
</script>

<style scoped>

</style>

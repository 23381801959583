<template>
    <div class="products">

        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice" class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>Products</h1>
                </div>

                <div class="product-block">
                    <div style="position:relative">

                        <figure class="prdImg"><img src="../../assets/images/suvidi-adattu-matta-new.png"
                                                    alt="Suvidi Organic Brown Rice"></figure>

                        <article>

                            <h2 class="sub-title">The Rice <br>
                                <span> Taste, texture, Nutrition Info</span></h2>

                            <p>Brown rice is an unrefined and unpolished whole grain which retains its nutrient-rich
                                bran and germ layer, which is absent in white rice. It has a nutty flavour and is
                                chewier than white rice. Brown rice is a natural wholesome food rich in essential
                                minerals, a wealth of vitamins, dietary fibre and powerful antioxidants.</p>

                            <p style="padding-top:20px"></p>

                            <h2 class="sub-title">

                                The Exclusivity
                            </h2>

                            <p>
                                The 'Ombathu Muri Adatt' & Wadakkanchery paddy fields produce only 350 tons of rice a
                                year. In lieu of being a mass produced crop, Suvidi is a limited edition organic rice
                                cultivated with thoughtful care. This choice produce is then packed in uniquely numbered
                                packets to guarantee that you receive the genuine, nutrient-rich organic brown rice.

                            </p>

<!--                            <div class="row  mt-4">-->
<!--                                <div class="col-6 col-lg-4 addtocart">-->
<!--                                    <button class="contact100-form-btn" type="submit">-->
<!--                                      <span>-->
<!--                                        Add To Cart-->
<!--                                      </span>-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                                <div class="col-6 col-lg-4 addtocart" id="cart-counter">-->
<!--                                    <button type="button" class="contact100-form-btn" v-on:click="decrease">-->
<!--                                        <b-icon class="h4 mb-0" icon="dash" aria-hidden="true"></b-icon>-->
<!--                                    </button>-->
<!--                                    <h3 class="counter-text">{{ counter }}</h3>-->
<!--                                    <button type="button" class="contact100-form-btn" v-on:click="increase">-->
<!--                                        <b-icon class="h4 mb-0" icon="plus" aria-hidden="true"></b-icon>-->
<!--                                    </button>-->
<!--                                </div>-->

<!--                            </div>-->

                        </article>

                        <div class="clear"></div>
                    </div>
                </div>

                <div class="cooking">
                    <article>
                        <h2 class="sub-title">Cooking Methods</h2>

                        <p> Contains 20% Bran (Organic Matta Rice). For best results, please follow these instructions
                            to savour the rich taste of the finest Organic Matta Rice. For a quantity of 1 cup (150g),
                            take 7 cups of water and cook it at high flame for atleast 50 minutes.</p>
                        <div class="clear"></div>
                    </article>
                </div>

                <div class="prod-btm">

                    <figure>
                        <img src="../../assets/images/rice-sack.png" alt="Suvidi Organic Brown Rice">
                    </figure>

                    <h2 class="sub-title"> Certification </h2>

                    <p>A produce is certified organic in India only if it has been cultivated using organic farming
                        methods for three consecutive years in the same land. The ‘Ombathu Muri Adatt’ & Wadakkanchery
                        paddy fields have successfully produced organic rice for the last five years, earning Suvidi the
                        distinction of being a certified organic rice brand.</p>

                    <p>
                        Suvidi has received certification from Lacon, an internationally recognised certifying agency,
                        the United States Department of Agriculture (USDA) and India Organic.
                    </p>

                    <figure class="vert-block">

                        <img src="../../assets/images/certifications-3-logos.png" alt="Suvidi Organic Brown Rice"
                             class="hide-520"/>

                        <img src="../../assets/images/certifications-3-logos-mob.png" alt="Suvidi Organic Brown Rice"
                             class="view-520"/>

                    </figure>

                    <div class="clear"></div>

                    <hr>

                    <div class="clear"></div>

                    <div class="class-cert-icons">

                        <figure>
                            <img src="../../assets/images/icon-mother-nature.png" alt="Suvidi Organic Brown Rice"/>
                            <figcaption> Mother Nature</figcaption>
                        </figure>

                        <figure>
                            <img src="../../assets/images/icon-pesticideFree.png" alt="Suvidi Organic Brown Rice"/>
                            <figcaption>
                                PesticideFree
                            </figcaption>
                        </figure>

                        <figure>
                            <img src="../../assets/images/icon-organic-rice.png" alt="Suvidi Organic Brown Rice"/>
                            <figcaption>

                                OrganicRice
                            </figcaption>
                        </figure>

                        <figure>
                            <img src="../../assets/images/icon-enviorntment.png" alt="Suvidi Organic Brown Rice"/>
                            <figcaption>

                                Environment Friendly
                            </figcaption>
                        </figure>

                        <figure>
                            <img src="../../assets/images/icon-recycle.png" alt="Suvidi Organic Brown Rice"/>
                            <figcaption>Recycle</figcaption>
                        </figure>

                        <div class="clear"></div>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->

    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'OurStory',
    components: {},
    data () {
        return {
            counter: 1
        };
    },
    methods: {
        increase: function () {
            this.counter++;
        },
        decrease: function () {
            this.counter--;
            if (this.counter < 1) {
                this.counter = 1;
            }
        }
    }
};
</script>

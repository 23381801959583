<template>
    <div class="ourstory">
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->

                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title mb-lg-5">
                    <h1>MY ORDERS</h1>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card flex-row m-0 mb-3 p-3 row"
                             v-for="(value, index) in orders" v-bind:key="index">
                            <div class="align-items-center col-12 col-lg-4 d-flex mb-3 mb-lg-0">
                                <div class="item-img">
                                    <img src="../../assets/images/rice-sack.png" class="img-fluid" style="width: 100px"
                                         alt="#">
                                </div>
                                <div class="item-name ml-3">
                                    <p>Order Id</p>
                                    <p>{{ value.id }}</p>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3 mb-3 mb-lg-0 d-flex align-items-center">
                                <p>
                                    <label><b>Amount</b></label>
                                    <br>
                                    ₹ {{ value.total_amount }}
                                </p>
                            </div>
                            <div class="col-6 col-lg-3 mb-3 mb-lg-0 d-flex align-items-center">
                                <p>
                                    <label><b>Date</b></label>
                                    <br>
                                    {{ value.created_date }}
                                </p>
                            </div>
                            <div class="col-12 col-lg-2 mb-3 mb-lg-0 d-flex align-items-center">
                                <p class="">
                                    <label><b>Status</b></label>
                                    <br>
                                    {{ value.order_status }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->

    </div>
</template>

<script>
// @ is an alias to /src

import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'MyOrders',
    components: {},
    data () {
        return {
            orders: []
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        loadData () {
            const that = this;
            axios.get(urls.frontEnd.users.myOrders).then(function (response) {
                that.orders = response.data.data;
            }).catch(function (err) {
                console.log(err);
            });
        }
    }
};
</script>

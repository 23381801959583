<template>

    <div class="contactUS">
        <notifications/>
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->

                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>My Profile</h1>
                </div>

                <div class="contact-feed">
                    <div class="align">
                        <h3 class="sub-title"
                            style="text-align:center; padding-bottom:10px; border-bottom:1px solid #dbc297; margin-bottom:15px">
                            Personal Information</h3>

                        <form class="contact100-form validate-form" autocomplete="off" @submit.prevent="userUpdate">

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Name is required">
                                        <span class="label-input100">Full Name:</span>
                                        <input class="input100" type="text" name="Name"
                                               placeholder="Enter full name *"
                                               v-model="profile.name">
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input"
                                         data-validate="Valid email is required: ex@abc.xyz">
                                        <span class="label-input100">Email:</span>
                                        <input class="input100" type="email" name="Email"
                                               placeholder="Enter email address *"
                                               v-model="profile.email">
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Mobile is required">
                                        <span class="label-input100">Mobile:</span>
                                        <input class="input100" type="text" maxlength="10" minlength="10"
                                           @keypress="onlyNumberKey($event)" name="Phone" aria-valuemax="15"
                                           placeholder="Enter mobile number"
                                           v-model="profile.phone"
                                           required>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>
                            <div id="alert"></div>
                            <div class="clear"></div>

                            <div class="row justify-content-center mt-4" style="color: white">
                                <div class="col-12 col-md-6 col-lg-3 mt-3 mt-md-0">
                                    <button class="contact100-form-btn w-100" type="submit">
                                  <span>
                                     Update
                                    <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                                  </span>
                                    </button>
                                </div>
                            </div>

                            <div class="clear"></div>

                        </form>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- contact feed -->

                <div class="clear"></div>
            </div>

            <!-- InstanceEndEditable --></div><!-- end welcome bg -->
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Profile',
    data () {
        return {
            profile: {},
            changingPassword: false,
            showPassword: false,
            model: {
                old_password: '',
                new_password: '',
                new_password_retype: ''
            },
            errorMessage: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        loadDetails () {
            const that = this;
            axios.get(urls.frontEnd.users.profile).then(function (response) {
                that.profile = response.data.data;
            }).catch(function (err) {
                console.log(err);
            });
        },
        onlyNumberKey (evt) {
            evt = (evt) || window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        // changePassword () {
        //     this.changingPassword = true;
        //     const component = this;
        //     axios.form(urls.frontEnd.users.changePassword, component.model).then(function (data) {
        //         const json = data.data;
        //         if (json.error === false) {
        //             alert('success\nNeed to login once again...');
        //             component.$bvModal.hide('modal-center1');
        //         } else {
        //             component.errorMessage = json.errors;
        //             alert('Something went wrong..! \nPlease try again.');
        //         }
        //         component.changingPassword = false;
        //     }).catch(function (err) {
        //         console.log('error =>', err);
        //         alert('Something went wrong..! \n Please try again.');
        //         component.changingPassword = false;
        //     });
        // },
        userUpdate () {
            const component = this;
            // console.log('data =>', component.profile);
            axios.form(urls.frontEnd.users.userUpdate, component.profile).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Profile Updated Successfully', 'success',
                        {
                            type: 'success',
                            duration: 3000,
                            permanent: false
                        });
                    component.profile = json.data;
                } else {
                    component.errorMessage = json.errors;
                    alert('Something went wrong..! \nPlease try again.');
                }
            }).catch(function (err) {
                console.log('error =>', err);
                alert('Something went wrong..! \n Please try again.');
            });
        }
    }

};
</script>

<style scoped>
@media (max-width: 800px) {
    .label-input100 {
        display: block !important;
    }
}
</style>

import guards from './guards';
const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/DashboardLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');

export default {
    path: '/admin/',
    name: 'AdminDashboardLayout',
    redirect: '/admin/home/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/admin/home/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/admin/category/',
            name: 'Category',
            component: () => import('../views/Admin/Category/ListCategory')
        },
        {
            path: '/admin/master-data/',
            name: 'Category',
            component: () => import('../views/Admin/Masterdata/ViewPage')
        },
        {
            path: '/admin/orders/',
            name: 'Category',
            component: () => import('../views/Admin/Orders/ListOrders')
        },
        {
            path: '/admin/order/:id/details/',
            name: 'OrderDetails',
            component: () => import('../views/Admin/Orders/OrderDetails')
        },
        {
            path: '/admin/transaction/:id/details/',
            name: 'TransactionDetails',
            component: () => import('../views/Admin/Transaction/TransactionDetails')
        },
        {
            path: '/admin/product/',
            name: 'Product',
            component: () => import('../views/Admin/Product/ListProduct')
        },
        {
            path: '/admin/transaction/',
            name: 'Transaction',
            component: () => import('../views/Admin/Transaction/ListTransaction')
        },
        {
            path: '/admin/users/',
            name: 'Users',
            component: () => import('../views/Admin/Users/ListUsers')
        }
    ]
};

<template>
    <div class="ourstores">
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <notifications></notifications>
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>Our Shop</h1>
                </div>

                <div class="card mb-3" v-for="(i, index) in products" v-bind:key="index">
                    <div class="row">
                        <div class="align-items-center col-lg-4 d-flex">
<!--                            <img src="../../assets/images/rice-sack.png" class="img-fluid" alt="#">-->
                            <img :src="i.product_image" alt="#">
                        </div>
                        <div class="col-lg-8 my-4  pr-lg-5">
                            <h2 class="sub-title">{{ i.product_name }}</h2>
                            <p class="d-none">{{ i.display_name }}</p>
                            <p class="mt-2 sub-title">
                                <small style="font-size: 15px">MRP</small> <s style="font-size: 20px">₹ {{ i.mrp }}</s> <br>
                                <b style="font-size: 25px; margin-right: 15px"><small style="font-size: 15px">Selling Price</small> ₹ {{ i.selling_price }}</b>
                            </p>
                            <p class="mt-2 d-none">{{ i.display_name }} : {{ i.description }}.</p>
                            <div class="clear"></div>
                            <div class="row  mt-4" v-if="currentUser.username">
                                <div class="col-6 col-lg-4 addtocart">
                                    <button class="contact100-form-btn" type="button" @click="addToCart(i)">
                                      <span>
                                        Add To Cart
                                      </span>
                                    </button>
                                </div>
                                <div class="col-6 col-lg-4 addtocart" id="cart-counter">
                                    <button :disabled="i.counter === 1" type="button" class="contact100-form-btn" @click.prevent="i.counter -= 1">
                                        <b-icon class="h4 mb-0" icon="dash" aria-hidden="true"></b-icon>
                                    </button>
                                    <h3 class="counter-text" style="color: black !important;">{{ i.counter }}</h3>
                                    <button type="button" class="contact100-form-btn" @click.prevent="i.counter += 1">
                                        <b-icon class="h4 mb-0" icon="plus" aria-hidden="true"></b-icon>
                                    </button>
                                </div>

                            </div>
                            <div v-else class="row mt-4">
                                <div class="col-6 col-lg-4 addtocart">
                                    <a class="contact100-form-btn" v-b-modal.modal-center role="button" style="color: white;">
                                        Please Login to Buy
                                    </a>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <p class="mt-2 col-6" style="font-weight: 400;"><strong>Free Delivery on Purchase of above 4Kg.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chbk" v-if="!products.length > 0">Please check back soon...</div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->
        <div v-show="showSuccess" class="card col-md-4 text-center center">
            {{ response }}
        </div>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'OurStores',
    components: {},
    data () {
        return {
            prefix: 'https://suvidiorganic.com',
            // prefix: 'http://127.0.0.1:8000',
            products: [],
            counter: 1,
            showSuccess: false,
            response: '',
            showButton: false
        };
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    mounted () {
        this.loadProducts();
    },
    methods: {
        loadProducts () {
            const that = this;
            axios.get(urls.userProduct).then(function (response) {
                that.products = response.data.data;
            }).catch(function (err) {
                console.log(err);
            });
        },
        addToCart (item) {
            const that = this;
            const postData = {
                product_id: item.id,
                quantity: item.counter
            };
            axios.form(urls.frontEnd.cart.addToCart, postData).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify({
                        type: 'success',
                        verticalAlign: 'top',
                        horizontalAlign: 'right',
                        message: json.message,
                        timeout: 3000
                    });
                    that.$router.push({ path: '/Cart' });
                } else {
                    that.$notify({
                        type: 'warning',
                        verticalAlign: 'top',
                        horizontalAlign: 'right',
                        message: 'Oops! Something went wrong',
                        timeout: 3000
                    });
                    that.$router.push({ path: '/our-stores' });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        increase: function (counter) {
            counter++;
        },
        decrease: function (counter) {
            counter--;
            if (counter < 1) {
                counter = 1;
            }
        }
    }
};
</script>

<style scoped>
.card {
  background-color: #e7d3b6;
  padding: 15px;
}

</style>

// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import 'argon/src/polyfills';

// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';

// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from 'argon/src/components/SidebarPlugin';

// element ui language configuration
// import lang from 'element-ui/lib/locale/lang/en';
// import locale from 'element-ui/lib/locale';
// vue-bootstrap

export default {
    install (Vue) {
        Vue.use(GlobalComponents);
        Vue.use(GlobalDirectives);
        Vue.use(SideBar);
    }
};

<template>
    <div class="SiteMap">
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->

        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>Sitemap</h1>
                </div>

                <div class="siteMap">

                    <ul>

                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/our-story">Our Story</router-link>
                        </li>
                        <li>
                            <router-link to="/products">Products</router-link>
                        </li>
                        <li>
                            <router-link to="/why-organic">Why Suvidi</router-link>
                        </li>
                        <li>
                            <router-link to="/farmer-stories">Farmer Stories</router-link>
                        </li>

                    </ul>

                    <ul>
                        <li>
                            <router-link to="/our-stores">Our Shop</router-link>
                        </li>
                        <li>
                            <router-link to="/contact">Contact Us</router-link>
                        </li>
                        <li>
                            <router-link to="/sitemap">Sitemap</router-link>
                        </li>
                        <li>
                            <router-link to="">Disclaimer</router-link>
                        </li>

                    </ul>

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div>

    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'SiteMap',
    components: {},
    data () {
        return {};
    }
};
</script>

import BaseDropdown from 'argon/src/components/BaseDropdown.vue';
import Card from 'argon/src/components/Cards/Card.vue';
import Modal from 'argon/src/components/Modal.vue';
import StatsCard from 'argon/src/components/Cards/StatsCard.vue';
import BaseButton from 'argon/src/components/BaseButton.vue';
import Badge from 'argon/src/components/Badge.vue';
import RouteBreadcrumb from 'argon/src/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from 'argon/src/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from 'argon/src/components/BaseSwitch.vue';
import BaseRadio from 'argon/src/components/Inputs/BaseRadio';
import BaseProgress from 'argon/src/components/BaseProgress';
import BasePagination from 'argon/src/components/BasePagination';
import BaseAlert from 'argon/src/components/BaseAlert';
import BaseNav from 'argon/src/components/Navbar/BaseNav';
import BaseHeader from 'argon/src/components/BaseHeader';
// import LoadingButton from 'v-bootstrap/src/LoadingButton';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Input, Popover, Tooltip } from 'element-ui';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install (Vue) {
        // Vue.component(LoadingButton.name, LoadingButton);
        Vue.component(Badge.name, Badge);
        Vue.component(BaseAlert.name, BaseAlert);
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(BaseCheckbox.name, BaseCheckbox);
        Vue.component(BaseHeader.name, BaseHeader);

        Vue.component(BaseDropdown.name, BaseDropdown);
        Vue.component(BaseNav.name, BaseNav);
        Vue.component(BasePagination.name, BasePagination);
        Vue.component(BaseProgress.name, BaseProgress);
        Vue.component(BaseRadio.name, BaseRadio);
        Vue.component(BaseSwitch.name, BaseSwitch);
        Vue.component(Card.name, Card);
        Vue.component(Modal.name, Modal);
        Vue.component(StatsCard.name, StatsCard);
        Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
        Vue.component(Input.name, Input);

        Vue.component('validation-provider', ValidationProvider);
        Vue.component('validation-observer', ValidationObserver);

        Vue.use(Tooltip);
        Vue.use(Popover);
    }
};

export default GlobalComponents;

<template>
    <div class="ourstory">
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->

                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>OUR STORY</h1>
                </div>

                <div class="imp-text">
                    <article>
                        <p> Aims at providing honestly organic products procured directly from Farmer-owned
                            Cooperatives. The wholehearted dedication of our farmers to grow genuine nutritious organic
                            food has earned SUVIDI the distinction of being a certified organic brand in India.</p>
                        <h2 class="sub-title">
                            Eat Healthy,

                            <span>
Live Rich
</span>
                        </h2>
                        <a id="journey"></a>
                        <p>SUVIDI envisions a healthy society, making healthy organic accessible to everyone. Completely
                            free of pesticides, fertilizers, additives, and other chemicals, SUVIDI is the child of
                            pure, life-giving earth. Each pack of SUVIDI provides a rich source of nutrition, magnesium,
                            Vitamin A, and B, thereby reducing the risk of diseases like diabetes.</p>

                    </article>

                </div>

                <div class="our-story">

                    <article>
                        <a id="inspiration"></a>
                        <h3 class="subTitle">The Journey</h3>
                        <p>
                            From the rice paddies of Kerala comes a story that brims with hope; a group of farmers
                            committed to growing nutritious food the natural way, and their determination that led to
                            the cultivation of Suvidi organic brown rice. It was a journey of going back to the roots,
                            how our ancestors farmed using only natural elements.

                        </p>

                        <p>Suvidi is a promise made to Mother Nature.</p>

                        <div class="clear"></div>

                    </article>

                    <article>

                        <img src="../../assets/images/hme-org-sketch.png" alt="suvidi organic brown rice"
                             class="img-right">
                        <h3 class="subTitle">The Inspiration</h3>

                        <p>
                            Organic is a way of life. It rejuvenates our lives and breathes new life into the earth.
                            Organic farming is the method of agriculture that avoids the use of chemicals throughout the
                            process. It takes us back to the natural way of living, free of chemical-ridden food and
                            lifestyle diseases. Organic farming positively impacts our health and environment by
                            producing rich grains with longer shelf life in its purest form, just like nature intended.

                        </p>

                    </article>

                    <article>

                        <h3 class="subTitle">
                            The Seed of Thought
                        </h3>

                        <p>
                            The thought behind Suvidi commenced in 2006, when CEDAR wished to reintroduce traditional
                            organic farming to the Adatt paddy fields in Thrissur, Kerala. However, the concept of
                            organic farming wasn't a welcoming one at the time, and the idea remained dormant for a few
                            years.
                        </p>

                    </article>

                    <article>

                        <h3 class="subTitle">The First Sprout</h3>

                        <p>

                            In 2013, a farmers' collective made the decision to cultivate rice organically in the Adatt
                            paddy fields. They received technical training and support from Kerala Agricultural
                            University, financial aid from the Government and NABARD, and support in marketing from
                            CEDAR.

                        </p>

                    </article>

                    <article>

                        <h3 class="subTitle">

                            The Process

                        </h3>

                        <p>
                            The process of farming at Adatt was inspired by the traditional organic system of farming
                            that had been in use for years. The paddy fields, situated below sea level, remain
                            waterlogged from June to November, during which farmers raise fish and ducks in them. In
                            January, this water is redirected to a canal, leaving the soil rich with natural fertiliser
                            from the animals. Panchagavya, a mixture of milk, curd, ghee, cow dung and urine, is the
                            only pesticide used.

                        </p>

                    </article>

                    <article>

                        <h3 class="subTitle">Over the Years</h3>

                        <p>
                            Two years into farming at Adatt, internal issues and other problems arose. Over the next few
                            years, the land saw several of its farmers returning to contemporary methods of cultivation.
                            The future of organic farming at Adatt remained bleak and uncertain.

                        </p>

                    </article>

                    <article>
                        <img src="../../assets/images/farmer.png" alt="suvidi organic brown rice" class="img-left">
                        <h3 class="subTitle">2018 - Birth of the Brand</h3>

                        <p>
                            The year 2018 proved to be a turning point. The farmers of Ombathu Muri, a section of the
                            Adatt paddy fields, formed a new collective and decided to continue doing organic farming
                            there in partnership with CEDAR. This crucial resolve is what led to the birth of the brand
                            Suvidi.

                        </p>

                    </article>

                    <!--
                    <article>

                    <img src="../assets/images/farmer.png" alt="suvidi organic brown rice" class="img-left">

                    <h3 class="subTitle">Tiny steps to a big leap</h3>

                    <p>Ombathu Muri is a group of paddy fields located in Adatt, Thrissur, owned by a farmers' co-operative. The name means ‘nine pieces/sections' in Malayalam. There are several persons who have played key roles in making organic rice farming in Ombathu Muri a reality. Deriving inspiration from the success story of Ombathumuri, another group of farmers from Wadakkanchery, which is also located in Thrissur district decided to join the initiative of SUVIDI. They stood as one, decided to go against the current and made a difference. Their perseverance has helped to bring forth a unique brand in organic products.</p>

                    </article>

                    -->

                    <article>

                        <h3 class="subTitle" id="organic">An 'Organic' Thought</h3>

                        <p>

                            The thought behind Suvidi is the zeal to produce rice the natural way, without using any
                            pesticides, fertilizers, additives or other chemicals.
                        </p>

                    </article>

                    <div style="display:none"> <!-- hided on 31 12 2020 -->

                        <article>

                            <h3 class="subTitle">Certification</h3>

                            <p>A produce is certified organic in India only if it has been cultivated using organic
                                farming methods for three consecutive years in the same land. The 'Ombathu Muri Adatt'
                                paddy fields have successfully produced organic rice for the last five years, earning
                                Suvidi the distinction of being a certified organic rice brand.</p>

                            <p>Suvidi has received certification from Lacon, an internationally recognised certifying
                                agency, the United States Department of Agriculture (USDA) and India Organic.</p>

                        </article>

                        <article>
                            <img src="../../assets/images/authentic-oraginc-new.png" alt="suvidi organic brown rice"
                                 class="img-right-org">
                            <h3 class="subTitle" id="rice-taste">

                                The Rice - Taste, Texture, Nutrition Info</h3>

                            <p>

                                Brown rice is an unrefined and unpolished whole grain which retains its nutrient-rich
                                bran and germ layer, which is absent in white rice. It has a nutty flavour and is
                                chewier than white rice. Brown rice is a natural wholesome food rich in essential
                                minerals, a wealth of vitamins, dietary fibre and powerful antioxidants. Apart from
                                highly nutritious, brown rice is gluten-free and can help improve several health
                                conditions.
                            </p>

                        </article>

                        <article>

                            <h3 class="subTitle">The Exclusivity</h3>

                            <p>

                                The 'Ombathu Muri Adatt' paddy fields produce approximately 100 tons of rice a year. In
                                lieu of being a mass produced crop, Suvidi is a limited edition organic rice cultivated
                                with thoughtful care. This choice produce is then packed in uniquely numbered packets to
                                guarantee that you receive the genuine, nutrient-rich organic brown rice.

                            </p>

                        </article>

                        <article>

                            <h3 class="subTitle">

                                Where to buy?</h3>

                            <p>
                                Suvidi organic brown rice is available for sale at all leading stores in Kerala. It can
                                also be purchased online.

                            </p>

                        </article>

                    </div>

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->

    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'OurStory',
    components: {},
    data () {
        return {};
    }
};
</script>

<template>
    <div class="farmer-stories">
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>Farmer Stories</h1>
                </div>

                <div class="farmer-story">
                    <ul>

                        <li>

                            <figure>
                                <img src="../../assets/images/farmer-unnikrishnan.jpg" alt="Suvidi Organic"/></figure>

                            <article>
                                <h3>
                                    Unnikrishnan
                                </h3>

                                <p>

                                    The man who proudly introduces himself as the son of Adat, the man who stands for
                                    the land which feeds him, Unnikrishnan is the young face of Adat. He who had
                                    witnessed both the usage of toxic pesticide and the suffrage of cancer affected
                                    people in his locale realize the correlation between both and had played a great
                                    role in the formation of Adat Farmers Service Co-operative Bank - a pool of
                                    conventional farmers whose 83 acres of paddy field are organically active. Realizing
                                    that the passion to serve pure rice won’t be enough to survive to make the farmers
                                    stood behind, he took the initiative to bring the legendry Adat yield to a
                                    profitable farming model. He is also keen to educate customers on all those
                                    mendacious Adat labeled brands which is available in the market and advocates for
                                    the one and only uncompromised true Adat-Suvidi.

                                </p>

                                <div class="clear"></div>

                                <div class="ytbLink">
                                    <a href="https://youtu.be/nDBBSzYADVA"
                                       target="_blank">https://youtu.be/nDBBSzYADVA</a>
                                </div>

                            </article>
                            <div class="clear"></div>
                        </li>

                        <li>

                            <figure>
                                <img src="../../assets/images/farmer-kumaran.jpg" alt="Suvidi Organic"/></figure>

                            <article>
                                <h3>Kumaran</h3>

                                <p>

                                    Kumaran is the man who is in charge of those 64 true farmers who had decided to
                                    carry forward the legacy of Adat. Being an expert on the detail of all organic
                                    applications including the Panchagavya he still believes that the inner urge to do
                                    the right thing is what drives him to these fields every morning. Now don’t mistake
                                    him as a conventional farmer who is rigid when they come in touch with technology.
                                    He is highly interested and adaptive towards the blending of the latest technology
                                    with agriculture with the support of the Agriculture University & Fisheries
                                    Department of Kerala. To survive in this business he had introduced seasonal fish
                                    farming which makes organic farming practice more promising. This simple farmer of
                                    ombathumuri offers quality to life which everyone deserves. Kumaran requests people
                                    not to see Organic food as an extra expense, but as an investment in themselves.
                                    It's time to act on his words.

                                </p>

                                <div class="clear"></div>

                                <div class="ytbLink">
                                    <a href="https://youtu.be/EVfD7YTNi64"
                                       target="_blank">https://youtu.be/EVfD7YTNi64</a>
                                </div>

                            </article>
                            <div class="clear"></div>
                        </li>

                        <li>

                            <figure>
                                <img src="../../assets/images/farmer-ammini.jpg" alt="Suvidi Organic"/></figure>

                            <article>
                                <h3>Ammini</h3>

                                <p>Hailing from a farming community, Ms. Ammini grew up around the lush green paddy
                                    fields of Adatt Onmbathu Muri Kole padavu in Thrissur. Being widowed at a very young
                                    age she became the sole bread earner in the family and with three kids to cater to
                                    she found it very difficult to financially sustain herself. Aware of all the
                                    processes involved in rice cultivation and her keen interest in agriculture came to
                                    her aid. Knowing all the traditional ways of rice cultivation she now leads the team
                                    and trains others on agriculture practices. “I can now take care of my needs and my
                                    family’s needs without depending on anyone”, says Ms. Ammini. Being able to
                                    differentiate between rice cultivated using chemical fertilizers and organic
                                    fertilizers; her advice to the generation living in cities is to choose organic rice
                                    over the one grown with chemical fertilizers in hope of building a community of
                                    healthy individuals. She also wishes the younger generation to be more aware of
                                    these traditional practices so that the knowledge can be passed on and such
                                    traditional organic ways of cultivating rice may not perish.</p>

                                <div class="clear"></div>

                                <div class="ytbLink">
                                    <a href="https://youtu.be/wlqLz0VMyQc"
                                       target="_blank">https://youtu.be/wlqLz0VMyQc</a>
                                </div>

                            </article>
                            <div class="clear"></div>
                        </li>

                        <li>

                            <figure>
                                <img src="../../assets/images/farmer-mani.jpg" alt="Suvidi Organic"/></figure>

                            <article>
                                <h3>Mani</h3>

                                <p>Going against the age-old cultural norm of women being dependent on their husbands
                                    and in law’s families, Ms. Mani felt empowered to remain unmarried and live her life
                                    being single. While she enjoyed the perks of being single she also had to struggle
                                    to get her basic needs fulfilled in a world where everything came at a monetary cost
                                    that many couldn’t bear. Ms. Mani tried hustling around doing multiple works to
                                    fulfill her needs, but what gave her financial stability was her decision to venture
                                    into the Agri sector. This decision gave her a livelihood opportunity thereby
                                    supporting her to render to her daily requirements. Ms. Mani makes use of the
                                    leftover rice that she receives from the paddy field and is a regular consumer of
                                    the organically grown SUVIDI rice which she says has made her healthier and has
                                    saved her medical costs. She advises the young and current generation to follow an
                                    organic lifestyle that is sustainable in the long run at the same time improves
                                    their health and quality of living.</p>

                                <div class="clear"></div>

                                <div class="ytbLink">
                                    <a href="https://youtu.be/3IzTpQSj8wI"
                                       target="_blank">https://youtu.be/3IzTpQSj8wI</a>
                                </div>

                            </article>
                            <div class="clear"></div>
                        </li>

                    </ul>
                    <div class="clear"></div>

                </div>

                <div class="video-thumbs" style="display:none">

                    <ul>
                        <!--<li><figure><img src="../assets/images/video-koithu-song.jpg" alt=""/>

                        <figcaption>
                        <h6>Suvidi Koithu Song</h6>
                        </figcaption>
                        </figure>
                        <a href="https://www.youtube.com/watch?v=t6LjuyVkGHA&feature=youtu.be" target="_blank" class="fullLink"></a>
                        </li>-->

                        <li>
                            <figure><img src="../../assets/images/video-kumaran.jpg" alt=""/>
                                <figcaption>
                                    <h6>Interview, Kumaran</h6>
                                </figcaption>
                            </figure>
                            <a href="https://www.youtube.com/watch?v=EVfD7YTNi64&feature=youtu.be" target="_blank"
                               class="fullLink"></a>
                        </li>

                        <li>
                            <figure><img src="../../assets/images/video-unnikrishnan.jpg" alt=""/>
                                <figcaption>
                                    <h6>Interview, Unnikrishnan</h6>
                                </figcaption>
                            </figure>
                            <a href="https://www.youtube.com/watch?v=nDBBSzYADVA&feature=youtu.be" target="_blank"
                               class="fullLink"></a>
                        </li>

                        <li>
                            <figure><img src="../../assets/images/video-farrmer.jpg" alt=""/>
                                <figcaption>
                                    <h6>Farmer Interview</h6>
                                </figcaption>
                            </figure>
                            <a href="https://www.youtube.com/watch?v=3IzTpQSj8wI&feature=youtu.be" target="_blank"
                               class="fullLink"></a>
                        </li>

                        <li>
                            <figure><img src="../../assets/images/video-farrmer-1.jpg" alt=""/>
                                <figcaption>
                                    <h6>Farmer Interview</h6>
                                </figcaption>
                            </figure>
                            <a href="https://www.youtube.com/watch?v=wlqLz0VMyQc&feature=youtu.be" target="_blank"
                               class="fullLink"></a>
                        </li>

                    </ul>
                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->

    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'FarmerStories',
    components: {},
    data () {
        return {};
    }
};
</script>

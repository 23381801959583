<template>
    <div class="products">

        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">
                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice" class="view620">
                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>
                        Why Suvidi?
                    </h1>
                </div>
                <h4 class="sub-caption">
                    Know the Suvidi route to Authentically Organic Product...
                </h4>
                <div class="organic-sub">
                    <ul>
                        <li>
                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/farmer-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">1. The Farmers</h2>
                                <p>

                                    Suvidi comes from the paddy fields in Adatt village and Wadakkanchery, both near
                                    Thrissur, Kerala. Suvidi is the harvest result of the
                                    <!-- Button trigger modal -->
                                    <a href="#" data-toggle="modal" id="b3" data-target="#agreement"><strong>
                                        joint group (Pada Sekhara Samithi)</strong></a>

                                    of 63 farmers who chose to shift to organic farming 5 years ago.

                                </p>

                                <div class="modal fade" id="agreement" tabindex="-1" role="dialog"
                                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialogAgreement  modal-dialog-centered"
                                         role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title suidiTitle" id="exampleModalCenterTitle"> PADDY
                                                    AGREEMENT

                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">

                                                <img src="../../assets/images/paddy-agreement.jpg" alt="paddy Agreement"
                                                     style="width:100%"></div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="clear"></div>
                        </li>

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/paddy-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">2. The Field</h2>
                                <p>
                                    The land became organic-qualified, that is, free from any chemical residues of
                                    pesticides and fertilisers during the five-year-long preparatory period.
                                </p>

                                <div class="clear"></div>

                            </div>
                        </li>

                        <br class="clear">

                    </ul>

                    <div class="clear"></div>

                    <ul>

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/manuring.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">3. Natural Manuring</h2>
                                <p>

                                    'Only organic manure like cow dung, leaf compost and growth promoters like

                                    <!-- Button trigger modal -->
                                    <a href="#" data-toggle="modal" id="b1" data-target="#panchagavya"><strong>Panchagavya </strong></a>
                                    (cow's urine, dung, milk, curd & ghee) etc. are used as manure.

                                </p>

                                <!-- Modal -->
                                <div class="modal fade" id="panchagavya" tabindex="-1" role="dialog"
                                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title suidiTitle" id="exampleModalCenterTitle">
                                                    PANCHAGAVYA

                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">

                                                <p> As its Sanskrit name denotes, Panchagavya is a concoction made up of
                                                    five cow products– Milk, Curd, Ghee, Cow Urine & Cow Dung. It is a
                                                    low-cost product capable of acting as an organic fertilizer and
                                                    pesticide used in agricultural operations. It plays a vital role in
                                                    the swift growth, and instilling immunity of plant system. </p>

                                                <p style="padding:15px 0 0 0; text-align:center"><strong>Benefits of
                                                    Panchagavya</strong></p>

                                                <div class="listing">

                                                    <p>Improves soil health and thereby fertility</p>
                                                    <p>100% natural and eco-friendly</p>
                                                    <p>Acts as an organic pesticide</p>
                                                    <p>Increases tillering</p>
                                                    <p>Absence of chaffy grains</p>
                                                    <p>Improved cooking quality</p>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="clear"></div>
                        </li>

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/duck-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">4. Biological Pest Control</h2>

                                <p>Pest control is done biologically through fishes and ducks, bio-decoctions like

                                    <!-- Button trigger modal -->
                                    <a href="#" id="b2" data-toggle="modal" data-target="#amino"><strong>

                                        fish amino.

                                    </strong></a>

                                </p>

                                <div class="modal fade" id="amino" tabindex="-1" role="dialog"
                                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title suidiTitle" id="exampleModalCenterTitle"> FISH
                                                    AMINO ACID

                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">

                                                <p> Fish Amino Acid (FAA) is an organic mixture produced from fish and
                                                    jaggery, which assists plants and micro-organisms in their growth.
                                                    Since it contains nutrients and various types of amino acids and is
                                                    a source of Nitrogen for crops, Fish Amino Acid triggers the growth
                                                    of crops rapidly.</p>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="clear"></div>
                        </li>
                        <br class="clear">

                    </ul>

                    <div class="clear"></div>

                    <ul>

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/rice-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">5. Bran-rich Processing</h2>

                                <p>The de-husking is done with special care so that you get the natural nutrient-rich
                                    bran (thavidu) for your better health.</p>

                            </div>
                            <div class="clear"></div>
                        </li>

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/certifications-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">6. International Certification</h2>

                                <p>LACON - the International Organic Certification Agency - certified the cultivation,
                                    storage, processing and packing procedures of Suvidi.</p>

                            </div>

                            <div class="clear"></div>
                        </li>

                        <br class="clear">
                    </ul>

                    <div class="clear"></div>

                    <ul>

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/limited-icn.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">7. Limited Edition</h2>

                                <p>

                                    <!--The Suvidi pack you get belongs to the 175 tons of paddy harvested during April 2018.-->

                                    <!--The Suvidi pack you get belongs to the 129 metric tons of paddy harvested during April 2018. -->

                                    <!--Each packet carries the unique serial number of release from this lot.-->

                                    The Suvidi pack you get belongs from the 350 tons of paddy harvested.
                                </p>

                            </div>

                            <div class="clear"></div>
                        </li>

                        <!-- <li>

                         <div class="align">

                        <figure>
                         <img src="../assets/images/icon-certificate.png" alt="Suvidi Organic Brown Rice">
                         </figure>

                      <h2 class="sub-title">8. You Can Verify</h2>

                      <p>Just scan the QR Code below or visit our website at www.suvidiorganic.com so that you get the full story and documents that back up Suvidi.</p>

                         </div>

                      <div class="clear"></div>
                      </li>-->

                        <li>

                            <div class="align">

                                <figure>
                                    <img src="../../assets/images/cv-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>

                                <h2 class="sub-title">8. A Covenant With Nature </h2>

                                <p>We are stringent about the quality of our product and rigidly adhere to the
                                    principles of organic farming. Here you can see how we record each step of our
                                    process.</p>

                            </div>

                            <div class="clear"></div>
                        </li>

                        <br class="clear">
                    </ul>

                    <ul class="lastCnt">

                        <li style="width:100%">

                            <figure style="text-align:center; padding-top:10px">

                                <img src="../../assets/images/board.png"
                                     alt="Suvidi Organic Brown Rice|Certified Organic Rice Brand in India"
                                     style="width:auto"></figure>

                        </li>

                        <br class="clear">

                    </ul>

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>

                <figure class="vert-block">

                    <h4> Certifications</h4>

                    <img src="../../assets/images/certifications-3-logos.png" alt="Suvidi Organic Brown Rice"
                         class="hide-520"/>

                    <img src="../../assets/images/certifications-3-logos-mob.png" alt="Suvidi Organic Brown Rice"
                         class="view-520"/>

                </figure>

                <!-- <figure style="text-align:center; padding-top:10px">

                <img src="../assets/images/board.png" alt="Suvidi Organic Brown Rice|Certified Organic Rice Brand in India"> </figure>

              -->

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->

    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'OurStory',
    components: {},
    data () {
        return {};
    }
};
</script>

import Index from '@/views/Users/Index';
import OurStory from '@/views/Users/OurStory';
import Products from '@/views/Users/Products';
import WhyOrganic from '@/views/Users/WhyOrganic';
import OurStores from '@/views/Users/OurStores';
import FarmerStories from '@/views/Users/FarmerStories';
import ContactUs from '@/views/Users/ContactUs';
import SiteMap from '@/views/Users/SiteMap';
import Cart from '@/views/Users/Cart';
import Checkout from '@/views/Users/Checkout';
import CheckoutDetails from '@/views/Users/CheckoutDetails';
import userGuard from '@/router/user-guard';
import MyOrders from '@/views/Users/MyOrders';
import Profile from '@/views/Users/Profile';

export default {
    path: '/',
    name: 'DashboardLayout',
    component: () => import('@/views/Users/base'),
    beforeEnter: userGuard.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/home',
            name: 'Index',
            component: Index
        },
        {
            path: '/our-story',
            name: 'OurStory',
            component: OurStory
        },
        {
            path: '/products',
            name: 'products',
            component: Products
        },
        {
            path: '/why-organic',
            name: 'WhyOrganicWhyOrganic',
            component: WhyOrganic
        },
        {
            path: '/our-stores',
            name: 'OurStores',
            component: OurStores
        },
        {
            path: '/farmer-stories',
            name: 'FarmerStories',
            component: FarmerStories
        },
        {
            path: '/contact',
            name: 'ContactUs',
            component: ContactUs
        },
        {
            path: '/sitemap',
            name: 'SiteMap',
            component: SiteMap
        },
        {
            path: '/Cart',
            name: 'Cart',
            component: Cart
        },
        {
            path: '/Checkout',
            name: 'Checkout',
            component: Checkout
        },
        {
            path: '/myorders',
            name: 'MyOrders',
            component: MyOrders
        },
        {
            path: '/myprofile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/transaction',
            name: 'CheckoutDetails',
            component: CheckoutDetails
        }

    ]
};

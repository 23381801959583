<template>
    <div class="ourstores">
        <div id="sub-page" style="padding-top: 250px"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">

                <div class="row">
                    <div class="col-12 text-center loader py-5">
                        <svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                             enable-background="new 0 0 100 100" xml:space="preserve">
                                 <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                                  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50"
                                                        to="360 50 50" repeatCount="indefinite"></animateTransform>
                                  </path>
                                                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                                  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
                                                        to="-360 50 50" repeatCount="indefinite"></animateTransform>
                                  </path>
                                                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                                  L82,35.7z">
                                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50"
                                                        to="360 50 50" repeatCount="indefinite"></animateTransform>
                                  </path>
                                </svg>
                        <h3 class="sub-title mt-4">
                            Transaction being processed...
                        </h3>
                        <h4>
                            Do not refresh the Page.
                        </h4>
                    </div>
                </div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->
            <div>
                <RedirectPage v-if="showRedirect" :model="model"></RedirectPage>
            </div>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import RedirectPage from '@/views/Users/RedirectPage';

export default {
    name: 'Details',
    components: { RedirectPage },
    data () {
        return {
            showRedirect: false,
            model: {
                action: '',
                address1: '',
                address2: '',
                amount: '',
                city: '',
                country: '',
                email: '',
                firstname: '',
                furl: '',
                hash_string: '',
                hash: '',
                key: '',
                lastname: '',
                merchant_key: '',
                phone: '',
                productinfo: '',
                service_provider: '',
                state: '',
                surl: '',
                txnid: '',
                udf1: '',
                udf2: '',
                udf3: '',
                udf4: '',
                udf5: '',
                zipcode: ''
            }
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        loadDetails () {
            const that = this;
            axios.form(urls.frontEnd.cart.proceedToPay, {}).then(function (respons) {
                const json = respons.data;
                // console.log('Json =>', json);
                if (json.error === false) {
                    that.redirectToPayee(json.payu);
                }
            }).catch(function (err) {
                console.log('error =>', err);
            });
        },
        redirectToPayee (data) {
            // console.log('data =>', data);
            this.model.action = data.action;
            this.model.address1 = data.address1;
            this.model.address2 = data.address2;
            this.model.amount = data.amount;
            this.model.city = data.city;
            this.model.country = data.country;
            this.model.email = data.email;
            this.model.firstname = data.firstname;
            this.model.furl = data.furl;
            this.model.hash_string = data.hash_string;
            this.model.hash = data.hashh;
            this.model.key = data.key;
            this.model.lastname = data.lastname;
            this.model.merchant_key = data.merchant_key;
            this.model.phone = data.phone;
            this.model.productinfo = data.productinfo;
            this.model.service_provider = data.service_provider;
            this.model.state = data.state;
            this.model.surl = data.surl;
            this.model.txnid = data.txnid;
            this.model.udf1 = data.udf1;
            this.model.udf2 = data.udf2;
            this.model.udf3 = data.udf3;
            this.model.udf4 = data.udf4;
            this.model.udf5 = data.udf5;
            this.model.zipcode = data.zipcode;
            try {
                this.showRedirect = true;
            } catch (e) {
                console.log('error =>', e);
            }
        }
    }
};
</script>

<style>
.loader svg {
    width: 6%;
    color: #2e4d09;
}
</style>

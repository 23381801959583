const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    baseUrl,
    auth: {
        login: baseUrl + 'users/login/',
        sendOtp: baseUrl + 'users/get-otp/',
        resendOTP: baseUrl + 'users/resent-otp/',
        validateOtp: baseUrl + 'users/validate-otp/',
        updateProfile: baseUrl + 'users/register/',
        status: baseUrl + 'users/status/',
        logout: baseUrl + 'users/logout/'
    },
    userProduct: baseUrl + 'product/user/product/list/',
    register: baseUrl + 'users/register/',
    frontEnd: {
        cart: {
            addToCart: baseUrl + 'cart/add-item/',
            updateCart: baseUrl + 'cart/update-cart/',
            removeCart: baseUrl + 'cart/remove-item/',
            itemList: baseUrl + 'cart/item-list/',
            stateOptions: baseUrl + 'master-data/state/options/',
            checkOut: baseUrl + 'cart/check-out/',
            orderSummary: baseUrl + 'cart/order/summary/info/',
            proceedToPay: baseUrl + 'transaction/payment-checkout/'
        },
        users: {
            profile: baseUrl + 'users/profile/',
            changePassword: baseUrl + 'users/change-password/',
            userUpdate: baseUrl + 'users/user-edit/',
            myOrders: baseUrl + 'users/my-orders/'
        },
        contactUs: baseUrl + 'users/contact-form/'
    },
    admin: {
        masterData: {
            deliveryCharge: {
                addEdit: baseUrl + 'master-data/delivery-charge/add-edit/',
                delete: baseUrl + 'master-data/delivery-charge/delete/',
                list: baseUrl + 'master-data/delivery-charge/list/'
            },
            deliveryException: {
                addEdit: baseUrl + 'master-data/delivery-exception/add-edit/',
                delete: baseUrl + 'master-data/delivery-exception/delete/',
                list: baseUrl + 'master-data/delivery-exception/list/'
            },
            termsAndCondition: {
                addEdit: baseUrl + 'master-data/terms-and-condition/add-edit/',
                list: baseUrl + 'master-data/terms-and-condition/list/',
                delete: baseUrl + 'master-data/terms-and-condition/delete/',
                activateDeactivate: baseUrl + 'master-data/terms-and-condition/activate-deactivate/',
                fileUrl: baseUrl + 'master-data/terms-and-condition/get-url/'
            }

        },
        category: {
            addEdit: baseUrl + 'product/category/add-edit/',
            delete: baseUrl + 'product/category/delete/',
            list: baseUrl + 'product/category/list/',
            options: baseUrl + 'product/category/options/'
        },
        orders: {
            changeStatus: baseUrl + 'cart/order/update-status/',
            list: baseUrl + 'cart/order/list/',
            details: baseUrl + 'cart/order/info-items/list/'
        },
        product: {
            addEdit: baseUrl + 'product/product/add-edit/',
            delete: baseUrl + 'product/product/delete/',
            list: baseUrl + 'product/product/list/'
        },
        transaction: {
            // addEdit: baseUrl + '',
            details: baseUrl + 'transaction/refund/transaction-list/',
            list: baseUrl + 'transaction/transaction-list/'
        },
        users: {
            list: baseUrl + 'users/user-list/'
        }
    }
};

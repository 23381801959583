<template>
    <div class="ourstores">
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div><!-- end sub banner -->
        <!-- end banner wrapp -->
        <div id="sub-page"><!-- InstanceBeginEditable name="EditRegion4" -->
            <div class="container">
                <div class="page-title">
                    <h1>Oops Something went wrong</h1>
                </div>

                <div class="chbk">404 - Page Not Found...</div>

                <div class="clear"></div>
            </div>
            <!-- InstanceEndEditable --></div><!-- end welcome bg -->

    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'NotFound',
    components: {},
    data () {
        return {};
    }
};
</script>

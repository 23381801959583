<template>
    <section class="cart-section header-mt">
        <notifications/>
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->

                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div>
        <div class="container">
            <div class="p-3 p-lg-5 cart-border">
                <div class="text-center">
                    <h3 class="sub-title"
                        style="text-align: center; padding-bottom: 10px; border-bottom: 1px solid rgb(219, 194, 151); margin-bottom: 15px;">
                        Your Cart</h3>
                </div>

                <div class="cart-table" v-show="items.length > 0">

                    <table class="table table-hover table-condensed table-responsive">
                        <thead>
                        <tr class="mn-color-1 jose-light c-font-16">
                            <th style="width:30%">Item</th>
                            <th style="width:18%">Price</th>
                            <th style="width:18%">Quantity</th>
                            <th style="width:18%" class="text-right">Total</th>
                            <th style="width:3%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, key)  in items" v-bind:key="key"
                            class="mn-color-1 cart-item-remove jose-light bold c-font-16">
                            <th style="width:30%" class="cart-img">
                                <span class=""> {{ item.order_item }}</span>
                            </th>
                            <th style="width:18%" class="">₹{{ item.item_price }}</th>
                            <th style="width:18%" class="">
                                <div class="wrap-input100 validate-input">
                                    <input type="number" class="input100" v-model="item.order_item_quantity"
                                           @blur="updateToCart(item)"/>
                                </div>
                            </th>
                            <th style="width:18%" class="text-right">₹{{ item.total }}</th>
                            <th>
                                <a class="cancel-link" v-on:click="removeElement(key, item)">
                                    <b-icon icon="x" font-scale="1.5"></b-icon>
                                </a>
                            </th>
                        </tr>
                        </tbody>
                    </table>

                    <div class=" col-md-12 mt-4 text-right ">
                        <p class="">
                            SUBTOTAL <span class="c-font-22"><b> ₹ {{ subTotal }} </b></span>
                        </p>
                    </div>
                </div>

                <div class="cart-empty my-5" v-show="items.length === 0">
                    <div class="alert-danger p-4 text-center">Your Cart is empty..!!</div>
                </div>

                <div class="form-section" v-show="items.length > 0">
                    <form action="#" class="">
                        <div class="row c-margin-t-20 justify-content-center">
                            <div class="col-sm-3">
                                <router-link to="/checkout" class="contact100-form-btn text-white"> CHECK OUT
                                </router-link>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Cart',
    data: function () {
        return {
            items: []
        };
    },
    mounted () {
        this.loadItems();
    },
    computed: {
        subTotal () {
            const items = this.items;
            let subTotal = 0;
            for (let i = 0; i < items.length; i++) {
                subTotal += items[i].total;
            }
            return subTotal;
        }
    },
    methods: {
        removeElement: function (index, item) {
            const that = this;
            axios.form(urls.frontEnd.cart.removeCart, { id: item.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.items.splice(index, 1);
                    that.$notify({
                        type: 'success',
                        verticalAlign: 'top',
                        horizontalAlign: 'right',
                        message: 'Item removed from cart.',
                        timeout: 3000
                    });
                }
            }).catch(function (err) {
                console.log('error =>', err);
            });
        },
        loadItems () {
            const that = this;
            axios.form(urls.frontEnd.cart.itemList, {}).then(function (response) {
                that.items = response.data.data;
            }).catch(function (err) {
                console.log('error =>', err);
            });
        },
        updateToCart (item) {
            const that = this;
            let orderQuantity = item.order_item_quantity || 1;
            if (orderQuantity === '0' || orderQuantity === 0) {
                orderQuantity = 1;
            }
            const postData = {
                product_id: item.id,
                quantity: orderQuantity
            };

            axios.form(urls.frontEnd.cart.updateCart, postData).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.setDataToCart(item.id, json.data);
                } else {
                    that.$notify({
                        type: 'success',
                        verticalAlign: 'top',
                        horizontalAlign: 'right',
                        message: 'Item adding to cart failed, Please try again later.',
                        timeout: 3000
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        setDataToCart (id, data) {
            const foundValue = this.items.filter(obj => obj.id === id);
            const curretItem = foundValue[0];
            curretItem.id = data.id;
            curretItem.order_item = data.order_item;
            curretItem.order_item_quantity = data.order_item_quantity;
            curretItem.item_price = data.item_price;
            curretItem.total = data.total;
            this.$notify({
                type: 'success',
                verticalAlign: 'top',
                horizontalAlign: 'right',
                message: 'Successfully Updated Quantity.',
                timeout: 3000
            });
        }
    }

};
</script>

<style scoped>
@media (max-width: 552px) {
    .cart-table .table th, .cart-table .table td {
        font-size: 70%;
    }

    .table td, .table th, .el-table td, .el-table th {
        white-space: normal;
    }
}
@media (max-width: 360px) {
    .cart-table .table th, .cart-table .table td {
        font-size: 60%;
    }
}
</style>

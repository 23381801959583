<template>
    <div class="home" id="page-wrap">
        <div id="page">
            <div class="bannerWrapp">
                <div style="position: relative">
                    <div class="bannerIMg" data-id="1">
                        <img src="../../assets/images/banner/suvidi-banner-01.jpg" alt="Suvidi Organic Brown Rice"
                             class="hide620"/>
                        <img src="../../assets/images/banner/suvidi-banner-01-mob.jpg" alt="Suvidi Organic Brown Rice"
                             class="view620"/>
                    </div>
                    <div class="banner-overlay">
                        <div class="container">
                            <div class="banner-caption" data-id="2">
                                <h4>
                                    From the rice paddies of Kerala comes a story that brims with
                                    hope; a group of farmers committed to growing nutritious food
                                    the natural way, and their determination...
                                </h4>
                                <h5 class="" data-id="3">
                                    <router-link class="text-uppercase" to="/our-story">read more</router-link>
                                </h5>
                            </div>
                            <figure class="bannimg">
                                <img src="../../assets/images/banner/banner-img-new.png" style="opacity: 0"/>
                            </figure>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end banner wrapp -->

            <div class="welcome-bg">
                <section class="welcomeWrapp ">
                    <div class="container">
                        <div style="position: relative">
                            <figure
                                class="hme-rice-img "
                                data-aos="fade-in"
                                data-id="1"
                            >
                                <img
                                    src="../../assets/images/hme-rice-bg.png"
                                    alt="Suvidi Organic Brown Rice"
                                />
                            </figure>
                            <article
                                class=""
                                data-aos="fade-up"
                                data-id="2"
                            >
                                <h1 class="welcome-title">
                                    <strong>Suvidi </strong>
                                    <br/>
                                    <span>Organic </span>
                                </h1>
                                <p>
                                    Aims at providing honestly organic products procured directly
                                    from Farmer-owned Cooperatives. The wholehearted dedication of
                                    our farmers to grow genuine nutritious organic food has earned
                                    SUVIDI the distinction of being a certified organic brand in
                                    India.
                                </p>
                                <div class="clear"></div>
                                <div class="rm-btn">
                                    <router-link to="/our-story">read more</router-link>
                                </div>
                                <div class="clear"></div>
                            </article>
                            <div class="clear"></div>
                        </div>
                    </div>
                </section>
                <!-- end welcome wrapp -->
                <section class="hme-video-wrap " data-sequence="500">
                    <div class="container">
                        <div style="position: relative">
                            <article class="hme-video-text">
                                <h2 class="widget-title " data-aos="fade-up" data-aos-delay="200" data-id="1">
                                    The People
                                </h2>
                                <p class="" data-aos="fade-up" data-aos-delay="200" data-id="2">
                                    OmbathuMuri is a group of paddy fields located in Adatt,
                                    Thrissur, owned by a farmers’ co-operative.
                                </p>
                                <div class="clear"></div>
                                <div class="rm-btn " data-aos="fade-up" data-aos-delay="200" data-id="3">
                                    <router-link to="/farmer-stories">read more</router-link>
                                </div>
                                <div class="clear"></div>
                            </article>

                            <img data-aos="fade-up"
                                 src="../../assets/images/hme-org-sketch.png"
                                 alt="Suvidi Organic Brown Rice"
                                 class="organic-img-rignt  go"
                                 data-id="4"
                            />

                            <div class="clear"></div>
                        </div>
                    </div>
                </section>

                <div class="hme-welcome-area-btm " data-sequence="500">
                    <div class="container">
                        <div class="hme-hundOrg">
                            <div class="hme-hundOrgTop">
                                <div class="orgTitleHme " data-aos="fade-up" data-aos-delay="200" data-id="1">
                                    <h2>100%</h2>
                                    <h3>Organic</h3>
                                </div>
                                <figure class="" data-aos="fade-up" data-aos-delay="200" data-id="2">
                                    <img
                                        src="../../assets/images/authentic-oraginc-new.png"
                                        alt="Suvidi Organic Brown Rice"
                                    />
                                </figure>
                                <div class="clear"></div>
                            </div>
                            <p class="" data-aos="fade-up" data-aos-delay="200" data-id="3">
                                A produce is certified organic in India only if it has been
                                cultivated using organic farming methods for three consecutive
                                years in the same land. The ‘Suvidi paddy fields’ have
                                successfully produced organic rice for the last five years,
                                earning Suvidi the distinction of being a certified organic rice
                                brand.
                            </p>
                            <div class="clear"></div>
                            <div class="rmyBtn " data-aos="fade-up" data-aos-delay="200" data-id="4">
                               <router-link to="/why-organic">read more</router-link>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- end btm -->
                <div class="clear"></div>
            </div>
            <!-- end welcome bg -->
            <section class="hmeFrmrWrapp " data-sequence="500">
                <div class="container">
                    <div style="position: relative">
                        <article class="hmeFar-blk">
                            <h2 class="widget-title " data-aos="fade-up" data-aos-delay="200" data-id="1">
                                the<br/>
                                <strong> Inspiration</strong>
                            </h2>
                            <p class="" data-aos="fade-up" data-aos-delay="200" data-id="2">
                                Organic is a way of life. It rejuvenates our lives and breathes
                                new life into the earth. Organic farming is the method of
                                agriculture that avoids the use of chemicals throughout the
                                process. It takes us back to the natural way of living, free of
                                chemical-ridden food and lifestyle diseases...
                            </p>
                            <div class="clear"></div>
                            <div class="rm-btn " data-aos="fade-up" data-aos-delay="200" data-id="3">
                                <router-link to="/our-story">read more</router-link>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <figure class="" data-aos="fade-up" data-id="4">
                            <img
                                src="../../assets/images/farmer.png"
                                alt="Suvidi Organic Brown Rice"
                            />
                        </figure>
                        <div class="clear"></div>
                    </div>
                </div>
            </section>
            <!-- end home farmer -->
            <div class="btmShade " data-sequence="500">
                <section class="partneStrip">
                    <div class="container">
                        <div class="partnerBdr " data-aos="fade-up" data-aos-delay="200" data-id="1">
                            <div class="partner-item">
                                <img
                                    src="../../assets/images/partners.png"
                                    alt="Suvidi Organic Brown Rice"
                                />
                            </div>
                            <div class="hme-hme-del " data-aos="fade-up" data-aos-delay="200" data-id="2">
                                <p>
                                    For Home Delivery
                                    <br/>
                                    & Trade Enquiries
                                </p>
                                <a href="tel:04872963603">0487-2963603</a>
                                <div class="clear"></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </section>
                <!-- end partner strips -->
                <figure class="org-nH-btm " data-sequence="500">
                    <img
                        src="../../assets/images/org-natural-healthy-new.png"
                        alt="Suvidi Organic Brown Rice"
                        class="hidemob500 " data-aos="fade-up"
                        data-id="1"
                    />
                    <img
                        src="../../assets/images/org-natural-healthy-mob-new.png"
                        alt="Suvidi Organic Brown Rice"
                        class="viewmob500 " data-aos="fade-up"
                        data-id="2"
                    />
                </figure>
                <div class="clear"></div>
            </div>

            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Index',
    components: {},
    data () {
        return {};
    }
};
</script>

<style scoped>
.footerWrapp.animatedParent {
    background-image: url(/img/footer-bg.4f641e46.jpg);
}

.sub-btm-wrapp {

    display: none;
}
</style>

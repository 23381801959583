import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import argon from './plugins/dashboard-plugin';

import 'font-icons/nunito/nunito.css';
import 'font-icons/simple-line-icons/css/simple-line-icons.css';
import 'font-icons/iconsmind-s/css/iconsminds.css';
import 'font-icons/nucleo/css/nucleo.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import './assets/argon.scss';
import './assets/federal-bank.scss';

import vuePerfectScrollbar from 'vue-perfect-scrollbar';
import components from './plugins/components';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './assets/css/suvidi-style.css';
import '../node_modules/nprogress/nprogress.css';
import './assets/css/suvidi-mobile-style.css';
import './assets/css/fonts.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);
Vue.use(argon);
Vue.use(components);

AOS.init({ delay: 1500 });
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
        AOS.init();
    }
};

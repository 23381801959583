<template>

    <div class="contactUS">

        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">
                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice" class="view620">
            </div>
        </div>
        <div id="sub-page">
            <div class="container">
                <div class="page-title">
                    <h1>
                        Contact Us
                    </h1>
                </div>
                <div class="contact-wrapp">
                    <div class="contact-top">
                        <div class="cnt-logo">
                            <img src="../../assets/images/cedar-logo.png" alt="Suvidi Organic Brown Rice"></div>
                        <address>
                            <p style="padding-bottom:10px"><strong> PROCESSED FOR</strong></p>
                            <p style="padding-bottom:10px">CEDAR Retail Pvt. Ltd., <br>
                                at Sree Krishna Modern Rice Mill<br>
                                NSS College P.O., Nenmara<br>
                                Palakkad - 678 508
                            </p>
                            <p>FSSAI : 11318009000136</p>
                        </address>
                        <address>
                            <p style="padding-bottom:10px"><strong>PACKED AND MARKETED BY</strong></p>
                            <p style="padding-bottom:10px">
                                CEDAR Retail Pvt. Ltd. <br>
                                7/7/732/9-11<br>
                                Green Tower, Mannuthy<br>
                                Mannuthy - Nadathara Road<br>
                                Pattalakkunnu, Thrissur – 680 651
                            </p>
                            <p>FSSAI: 11318008001403</p>
                        </address>
                        <address class="pdcdBy">
                            <p> For queries and suggestions please call
                                <br>
                                Customer care number: 0487-2963603</p>
                        </address>
                        <div class="clear"></div>
                    </div>

                    <div class="cnt-brm">
                        <div style="position:relative">
                            <div class="domain">
                                <figure>
                                    <img src="../../assets/images/domain-icon.png" alt="Suvidi Organic Brown Rice">
                                </figure>
                                www.suvidiorganic.com
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>

                <div class="contact-feed">
                    <div class="align">
                        <h3 class="sub-title"
                            style="text-align:center; padding-bottom:10px; border-bottom:1px solid #dbc297; margin-bottom:15px">
                            Send Us Your Feedback
                        </h3>
                        <div class="text-center text-lg-left " v-if="state === 'S'" key="'S">
                            <div id="alert"><span class="error">The mail has been successfully submitted.</span></div>
                            <div class="text-center">
                                <router-link to="/" class="btn btn-outline-secondary  xeo-button-2 btn-sm">Back
                                </router-link>
                            </div>
                        </div>

                        <div class="text-center text-lg-left " v-else-if="state === 'F'" key="'F">
                            <div id="alert"><span class="error">The mail delivery has been failed.</span></div>
                            <div class="text-center">
                                <router-link to="/" class="btn btn-outline-secondary xeo-button-2 btn-sm">Back
                                </router-link>
                            </div>
                        </div>

                        <div class="alert alert-info mb-0 w-100  text-center" v-else-if="state === 'L'" key="'L">
                            <div class="">
                                <loading-animation/>
                            </div>
                            <p class="mb-0 mt-4">Please wait while communicate with the server</p>
                        </div>

                        <form id="contact-form" v-else @submit.prevent="onSubmit" autocomplete="off">

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Name is required">
                                        <span class="label-input100">Full Name:</span>
                                        <input class="input100" type="text" name="userName"
                                               placeholder="Enter full name *" v-model="name"
                                               required>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input"
                                         data-validate="Valid email is required: ex@abc.xyz">
                                        <span class="label-input100">Email:</span>
                                        <input class="input100" type="email" name="userEmail"
                                               placeholder="Enter email addess *"
                                               v-model="email" required>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Phone is required">
                                        <span class="label-input100">Phone:</span>
                                        <input class="input100" type="text" name="phone"
                                               placeholder="Enter phone number *" v-model="phone"
                                               required>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Mobile is required">
                                        <span class="label-input100">Mobile:</span>
                                        <input class="input100" type="text" name="mob" placeholder="Enter mobile number"
                                               v-model="mobile"
                                               required>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Address is required">
                                        <span class="label-input100">Address:</span>
                                        <textarea class="input100" name="address" placeholder="Your Address"
                                                  v-model="address"
                                                  required></textarea>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="fom-field">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="Message is required">
                                        <span class="label-input100">Message:</span>
                                        <textarea class="input100" name="message" placeholder="Your Comment..."
                                                  v-model="message"
                                                  required></textarea>
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>

                            <div id="alert"></div>

                            <div class="clear"></div>

                            <div class="fom-field frmBtnFIeld"
                                 style="float:none; margin:0 auto; width:100%; clear:both">
                                <div class="fom-align0">
                                    <div class="container-contact100-form-btn" style="text-align:center; width:100%">
                                        <input type="hidden" name="recaptcha_response" id="recaptchaResponse">
                                        <button id="submit-button" class="contact100-form-btn" type="submit"
                                                style="margin:0 auto">
                                            <span>
                                                Submit
                                                <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>

                        </form>

                        <div class="clear"></div>
                    </div>
                </div>

                <div class="clear"></div>

            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import LoadingAnimation from '@/components/LoadingAnimation';
import urls from '@/data/urls';

export default {
    name: 'ContactUs',

    components: { LoadingAnimation },
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            phone: '',
            mobile: '',
            address: '',
            message: ''
        };
    },
    methods: {
        onSubmit () {
            console.log('Here....');
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('mobile', this.mobile);
            bodyFormData.append('address', this.address);
            bodyFormData.append('message', this.message);

            const that = this;

            this.state = 'L';

            axios({
                method: 'post',
                url: urls.frontEnd.contactUs,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response.data);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },
        onSubmitSuccess (response) {
            if (response.error === false) {
                this.state = 'S';
            } else {
                this.state = 'F';
            }
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style>
.grecaptcha-badge {
    z-index: 60000000 !important
}

.xeo-button-2 {
    color: #fff;
    margin-top: 10px;
}
</style>

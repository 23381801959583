<template>

    <section class="cart-section header-mt">
        <notifications/>
        <div class="sub-banner animatedParent" data-sequence='500'>
            <div class="animated fadeIn" data-id='1'>
                <!-- InstanceBeginEditable name="EditRegion3" -->

                <img src="../../assets/images/banner/sub-banner.jpg" alt="Suvidi Organic Brown Rice" class="hide620">

                <img src="../../assets/images/banner/sub-banner-mob.jpg" alt="Suvidi Organic Brown Rice"
                     class="view620">

                <!-- InstanceEndEditable --></div>
        </div>
        <div class="container" v-show="showCartCheckout">
            <div class="p-3 p-lg-5 cart-border">
                <div class="text-center">
                    <h3 class="sub-title"
                        style="text-align: center; padding-bottom: 10px; border-bottom: 1px solid rgb(219, 194, 151); margin-bottom: 15px;">
                        Checkout</h3>
                </div>

                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                    <form id="checkout-form" autocomplete="off" role="form"
                          @submit.prevent="handleSubmit(continueCheckout)">
                        <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
                        <div class="alert alert-info" v-if="infoMessage" v-html="infoMessage"></div>
                        <div class="clear"></div>
                        <div class="row">

                            <div class="fom-field col-12">

                                <div class="fom-align">

                                    <div class="wrap-input100 validate-input" data-validate="Address is required">
                                        <span class="label-input100">Address:</span>
                                        <textarea class="input100" name="Address" placeholder="Your Address"
                                                  v-model="model.address"
                                                  required></textarea>
                                        <span class="focus-input100"></span>
                                    </div>

                                </div>
                            </div>

                            <div class="fom-field col-6">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input" data-validate="pin code is required">
                                        <span class="label-input100">Pin code:</span>
                                        <input class="input100" type="text" name="Pin Code" maxlength="6" minlength="6"
                                               placeholder="Pin Code *" required @keypress="onlyNumberKey($event)"
                                               pattern="[0-9]*"
                                               v-model="model.pin_code">
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="clear"></div>

                            <div class="fom-field col-6 pl-lg-0">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input">
                                        <span class="label-input100">Mobile:</span>
                                        <input class="input100" name="Phone" maxlength="15" minlength="10" required
                                               placeholder="Alternative Mobile Number" @keypress="onlyNumberKey($event)"
                                               v-model="model.land_phone" type="tel" pattern="[0-9]*">
                                        <span class="focus-input100"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="fom-field  col-6">
                                <div class="fom-align">
                                    <div class="wrap-input100 validate-input select-c"
                                         data-validate="Address is required">
                                        <span class="label-input100">State:</span>
                                        <validated-select class="input100" name="State"
                                                          :options-u-r-l="StateOptions" required
                                                          :show-blank="true"
                                                          v-model="model.state"></validated-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div id="alert"></div>
                        <div class="clear"></div>

                        <div class="fom-field frmBtnFIeld" style="float:none; margin:0 auto; width:100%; clear:both">

                            <div class="fom-align0">

                                <div class="container-contact100-form-btn" style="text-align:center; width:100%">

                                    <input type="hidden" name="recaptcha_response" id="recaptchaResponse">
                                    <button id="submit-button" class="contact100-form-btn" type="submit"
                                            style="margin:0 auto">
                            <span>
                                Submit
                                <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>

                                    </button>

                                </div>

                            </div>
                        </div>

                        <div class="clear"></div>

                    </form>
                </validation-observer>

            </div>
        </div>
        <div class="container" v-show="showCheckoutDetails">
            <div class="p-3 p-lg-5 cart-border">
                <div v-if="profile_complete_message" class="text-center alert-danger card p-4">Kindly complete your
                    profile to proceed with the payment.
                </div>
                <div class="text-center">
                    <h3 class="sub-title"
                        style="text-align: center; padding-bottom: 10px; border-bottom: 1px solid rgb(219, 194, 151); margin-bottom: 15px;">
                        Details Summary</h3>
                </div>
                <div class="ourstores">
                    <div><!-- InstanceBeginEditable name="EditRegion4" -->
                        <div v-if="loading" class="row">
                            <div class="col-12 text-center loader py-5">
                                <svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                     enable-background="new 0 0 100 100" xml:space="preserve">
                                 <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                                  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                      <animateTransform attributeName="transform" attributeType="XML" type="rotate"
                                                        dur="2s" from="0 50 50"
                                                        to="360 50 50" repeatCount="indefinite"></animateTransform>
                                  </path>
                                    <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                                  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                      <animateTransform attributeName="transform" attributeType="XML" type="rotate"
                                                        dur="1s" from="0 50 50"
                                                        to="-360 50 50" repeatCount="indefinite"></animateTransform>
                                  </path>
                                    <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                                  L82,35.7z">
                                      <animateTransform attributeName="transform" attributeType="XML" type="rotate"
                                                        dur="2s" from="0 50 50"
                                                        to="360 50 50" repeatCount="indefinite"></animateTransform>
                                  </path>
                                </svg>
                                <h3 class="sub-title mt-4">
                                    Checkout in process...
                                </h3>
                                <h4>
                                    Do not refresh the Page.
                                </h4>
                            </div>
                        </div>
                        <!-- InstanceEndEditable --></div><!-- end welcome bg -->
                </div>
                <div v-if="!loading">
                    <h2 class="font-weight-bold mb-3">Cart Details</h2>
                    <div class="row mb-4" v-if="details">
                        <div class="col">Order Id : <span class="text-muted" v-if="details.id">{{ details.id }}</span>
                        </div>
                        <div class="col">Total Amount : <span class="text-muted"
                                                              v-if="details.total_amount">{{
                                details.total_amount
                            }}</span>
                        </div>
                        <div class="col">Delivery Charge : <span class="text-muted" v-if="details.delivery_charge">{{
                                details.delivery_charge
                            }}</span></div>
                        <div class="col">Grand Total : <span class="text-muted"
                                                             v-if="details.grand_total">{{ details.grand_total }}</span>
                        </div>
                    </div>
                    <h2 class="font-weight-bold mb-3">Order Items</h2>
                    <simple-table class="table-responsive" v-if="details" :fields="fields" :data="items"/>
                    <div class="fom-align0">
                        <div class="container-contact100-form-btn" style="text-align:center; width:100%">
                            <button v-if="is_profile_complete" id="submit-button1" @click="proceedCheckOut"
                                    class="contact100-form-btn"
                                    type="submit"
                                    style="margin:0 auto">
                            <span>
                                Proceed
                                <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Checkout',
    data () {
        return {
            details: null,
            items: [],
            showCartCheckout: true,
            showCheckoutDetails: false,
            is_profile_complete: '',
            profile_complete_message: '',
            errorMessage: '',
            infoMessage: '',
            loading: false,
            StateOptions: urls.frontEnd.cart.stateOptions,
            model: {
                address: '',
                state: '',
                land_phone: '',
                pin_code: ''
            },
            fields: [
                {
                    name: 'sl_no',
                    sortField: 'sl_no',
                    title: 'Sl No.'
                },
                {
                    name: 'order_item',
                    sortField: 'order_item',
                    title: 'Item'
                },
                {
                    name: 'item_price',
                    sortField: 'item_price',
                    title: 'Item Price'
                },
                {
                    name: 'order_item_quantity',
                    sortField: 'order_item_quantity',
                    title: 'Order Item Quantity'
                },
                {
                    name: 'total_amount',
                    sortField: 'total_amount',
                    title: 'Total Amount'
                }
            ]
        };
    },
    methods: {
        async continueCheckout () {
            const formValidator = this.$refs.formValidator;
            if (((this.model.address).trim()).length < 10) {
                this.$notify('Invalid address, must contain at least 10 letter except white spaces.', 'Error',
                    {
                        type: 'danger'
                    });
            } else {
                const that = this;
                const response = await axios.form(urls.frontEnd.cart.checkOut, that.model);
                const json = response.data;
                if (json.error === false) {
                    that.showCartCheckout = false;
                    that.showCheckoutDetails = true;
                    that.checkOut();
                } else {
                    formValidator.setErrors(json.errors);
                    that.errorMessage = json.errors.__all__ || '';
                }
            }
        },
        async checkOut () {
            this.loading = true;
            const that = this;
            const response = await axios.get(urls.frontEnd.cart.orderSummary);
            const json = response.data;
            if (json.error === false) {
                that.loading = false;
                that.details = json.cart_details;
                that.items = json.cart_items;
                that.is_profile_complete = json.is_profile_complete;
                if (that.is_profile_complete !== true) {
                    that.profile_complete_message = true;
                }
            } else {
                that.errorMessage = json.errors.__all__;
            }
            this.loading = false;
        },
        proceedCheckOut () {
            this.$router.push({ path: '/transaction' });
        },
        onlyNumberKey (evt) {
            evt = (evt) || window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
};
</script>

<style scoped>
.fom-field {
    width: 100%;
}

</style>
